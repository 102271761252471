import React, {useCallback, useEffect, useRef, useState} from 'react';
import Header from '../../components/Header';
import Search from '../../components/Search';
import { Link } from 'react-router-dom';
import {CategoryDTO} from "../../dtos/category.dto";
import {OrderDTO, OrderState} from "../../dtos/order.dto";
import {BaseItemDTO} from "../../dtos/item.dto";
import {toast} from "react-toastify";
import {API_URL} from "../../utils/constants";
import {useRecoilState, useRecoilValue} from "recoil";
import {adminState, fetchAPIResponseSelector} from "../../store/user.atom";
import {useNavigate} from "react-router";
import {io, Socket} from "socket.io-client";
import DatePicker from "react-datepicker";

const Orders = () => {
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);

    const [events, setEvents] = useState<string[]>([]);
    const [orders, setOrders] = useState<OrderDTO[]>([]);
    const [adminDTO] = useRecoilState(adminState);

    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(1);
    const observer = useRef<IntersectionObserver | null>(null);
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const [orderState, setOrderState] = useState<OrderState | undefined>(undefined);
    const navigate = useNavigate();
    const [socket, setSocket] = useState<Socket | null>(null);

    const [startDate, setStartDate] = useState<Date | undefined>(startOfDay);
    const [endDate, setEndDate] = useState<Date | undefined>(endOfDay);

    const pageRef = useRef(page);
    const totalPagesRef = useRef(totalPages);

    useEffect(() => {
        pageRef.current = page;
        totalPagesRef.current = totalPages;
    }, [page, totalPages]);

    useEffect(() => {
        if (adminDTO?.token) {
            const newSocket = io(API_URL, {
                query: { token: adminDTO.token },
                transports: ['websocket']
            });

            setSocket(newSocket);

            const notificationSound = new Audio('/notification.mp3');

            newSocket.on('order', (order) => {
                setOrders((orders) => [order, ...orders]);

                // Play notification sound
                notificationSound.play().catch(error => console.error('Error playing sound:', error));
            });

            newSocket.on('connect_error', (error) => {
                console.error('Connection error:', error);
            });

            return () => {
                newSocket.close();
            }
        }
    }, []);


    useEffect(() => {
        fetchOrders(true)
    }, [orderState, startDate, endDate]);

    useEffect(() => {
        if(page !== 1) {
            fetchOrders(false)
        }
    }, [page]);

    const fetchOrders = async (reset: boolean = false) => {
        try {
            let fetchUrl = `/orders/?size=${size}&from=${startDate}&to=${endDate}`;

            if(orderState !== undefined) {
                fetchUrl += `&state=${orderState}`
            }

            if(reset) {
                fetchUrl += `&page=1`
            } else {
                fetchUrl += `&page=${page}`
            }

            const response = await fetchAPIResponse<{
                pagination_options: any,
                contents: OrderDTO[]
            }>(fetchUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            setOrders(prevItems => (reset ? response.data.contents : [...prevItems, ...response.data.contents]));
            setTotalPages(response.data.pagination_options.total_pages);

            if(reset) {
                setPage(1)
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    useEffect(() => {
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && pageRef.current < totalPagesRef.current) {
                setPage(prevPage => prevPage + 1);
            }
        });
        return () => {
            observer.current?.disconnect();
        };
    }, []);

    const handleEdit = (itemId: any) => {
        navigate(`/items/edit/${itemId}`);
    };

    const handleDelete = async (itemId: any) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this item?');
        if (confirmDelete) {
            try {
                await fetchAPIResponse(`/items/admin/${itemId}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                setPage(1)
                setOrders([])
                fetchOrders();
            } catch (error) {
                toast.error((error as Error).message);
            }
        }
    };

    const lastNodeRef = useRef<Element | null>(null);

    const lastItemRef = useCallback((node: HTMLDivElement | null) => {
        if (observer.current) {
            if (lastNodeRef.current) {
                observer.current.unobserve(lastNodeRef.current);
            }
            if (node) {
                observer.current.observe(node);
                lastNodeRef.current = node;
            }
        }
    }, []);

    const handleStateChange = async (orderId: string, newState: OrderState) => {
        try {
            setOrders(orders.map(r => {
                if(r.id === orderId) {
                    r.state = newState as OrderState;
                }

                return r;
            }));

            await fetchAPIResponse(`/orders/${orderId}/status`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    toState: newState
                })
            });

        } catch (error) {
            toast.error((error as Error).message);
        }
    };

    const getOrderColor = (order: OrderDTO) => {
        switch (order.state) {
            case OrderState.PENDING:
                return "bg-orange-500";
            case OrderState.PREPARING:
                return "bg-yellow-500";
            case OrderState.DELIVERING:
                return "bg-blue-500";
            case OrderState.DELIVERED:
                return "bg-green-500";
            case OrderState.CANCELLED:
                return "bg-red";
        }
    }
    return (
        <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
            <Header title={'Orders'} button={false} buttonTitle={''} action={''} />
            <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
                <div className='my-4'>
                    <select
                        value={orderState}
                        onChange={(e) => setOrderState(e.target.value as OrderState)}
                        className='p-2 border rounded'
                    >
                        <option value="">All Order States</option>
                        {Object.values(OrderState).map((state) => (
                            <option key={state} value={state}>
                                {state.charAt(0) + state.slice(1).toLowerCase()}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='w-full flex items-center justify-between'>
                    <div className='flex flex-col items-center'>
                        <label htmlFor="startDate">From Date</label>
                        <DatePicker
                            id="startDate"
                            selected={startDate}
                            onChange={date => {
                                if (date != null) setStartDate(date)
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            isClearable
                            placeholderText="Select a start date"
                            className="p-2 rounded-md border border-gray-300"
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <label htmlFor="endDate">To Date</label>
                        <DatePicker
                            id="endDate"
                            selected={endDate}
                            onChange={date => {
                                if (date != null) setEndDate(date)
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            isClearable
                            placeholderText="Select an end date"
                            className="p-2 rounded-md border border-gray-300"
                        />
                    </div>
                </div>
                <div className='w-full overflow-visible flex-grow'>
                    <div className="w-full overflow-visible">
                        <div className='w-full grid grid-cols-7 font-bold text-mainTextColor my-4'>
                            <div>Order Date</div>
                            <div className={"ml-14"}>Order State</div>
                            <div>User</div>
                            <div>Phone Number</div>
                            <div>Order Total</div>
                            <div>Actions</div>
                        </div>
                        <hr className='border-t border-[#cdcdd0]'/>
                        {orders.map((order, index) => (
                            <div
                                key={order.id}
                                className={`w-full grid grid-cols-7 my-4 ${getOrderColor(order)}`}
                                ref={index === orders.length - 1 ? lastItemRef : null}
                            >
                                <div className='w-96 text-TextBlue flex items-center'>
                                    <p className='underline'>{new Date(order.order_date).toLocaleString('en-US', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit'
                                    })}
                                    </p>
                                </div>
                                <div className='ml-14 text-TextBlue flex items-center'>
                                    <select
                                        value={order.state}
                                        onChange={(e) => handleStateChange(order.id, e.target.value as OrderState)}
                                        className={`rounded-md border border-black p-1 ${getOrderColor(order)}`}
                                    >
                                        <option value="PENDING">Pending</option>
                                        <option value="PREPARING">Preparing</option>
                                        <option value="DELIVERING">Delivering</option>
                                        <option value="DELIVERED">Delivered</option>
                                        <option value="CANCELLED">Cancelled</option>
                                    </select>
                                </div>
                                <div className='text-TextBlue flex items-center'>
                                    <p className=''>{order.user.name}</p>
                                </div>
                                <div className='flex items-center'>
                                    <p className=''>{order.user.phone_number}</p>
                                </div>
                                <div className='flex items-center'>
                                    <p className=''>${order.total}</p>
                                </div>
                                <div className='flex items-center space-x-4'>
                                    <Link to={`/orders/view/${order.id}`} className='text-[#1490C2] underline'>View
                                        Details</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Orders;
