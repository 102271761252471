import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header';
import { useRecoilValue } from 'recoil';
import {fetchAPIResponseSelector} from "../../store/user.atom";
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";

type Image = {
  id: number;
  imageUrl: string;
};

type Delivery = {
  distance_from: number;
  distance_to: number;
  price: number;
};

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

  // State for carousel images
  const [carouselImages, setCarouselImages] = useState<Image[]>([]);
  const [newImage, setNewImage] = useState<string>('');

  // State for delivery interval
  const [deliveryData, setDeliveryData] = useState<Delivery[]>([]);
  const [upperLimit, setUpperLimit] = useState<string>('');
  const [lowerLimit, setLowerLimit] = useState<string>('');
  const [price, setPrice] = useState<string>('');

  const [cashbackRate, setCashbackRate] = useState<number>(0);
  const [loyalty, setLoyalty] = useState<boolean>(false);
  const [globalDiscount, setGlobalDiscount] = useState<number>(0);
  const [shopStatus, setShopStatus] = useState<boolean>(false);

  // Fetch delivery intervals from API
  useEffect(() => {
    const fetchDeliveryIntervals = async () => {
      try {
        const response = await fetchAPIResponse<Delivery[]>('/settings/delivery/intervals', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        setDeliveryData(response.data);
      } catch (error) {
        console.error('Error fetching delivery intervals:', error);
      }
    };

    const fetchCashbackRate = async () => {
      try {
        const response = await fetchAPIResponse<number>('/settings/cashback', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        setCashbackRate(response.data)
      } catch (error) {
        console.error('Error fetching cashback intervals:', error);
      }
    };

    const fetchLoyaltyState = async () => {
      try {
        const response = await fetchAPIResponse<boolean>('/settings/loyalty', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        setLoyalty(response.data)
      } catch (error) {
        console.error('Error fetching cashback intervals:', error);
      }
    };

    const fetchGlobalDiscount = async () => {
      try {
        const response = await fetchAPIResponse<number>('/settings/global_discount', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        setGlobalDiscount(response.data)
      } catch (error) {
        console.error('Error fetching cashback intervals:', error);
      }
    };

    const fetchShopStatus = async () => {
      try {
        const response = await fetchAPIResponse<boolean>('/settings/shop/status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        setShopStatus(response.data)
      } catch (error) {
        console.error('Error fetching cashback intervals:', error);
      }
    };

    fetchDeliveryIntervals();
    fetchCashbackRate();
    fetchLoyaltyState();
    fetchGlobalDiscount();
    fetchShopStatus();
  }, [fetchAPIResponse]);

  // Handler for adding image
  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setCarouselImages(prevImages => [...prevImages, { id: Date.now(), imageUrl }]);
    }
  };

  // Handler for deleting image
  const handleDeleteImage = (id: number) => {
    const updatedImages = carouselImages.filter(image => image.id !== id);
    setCarouselImages(updatedImages);
  };

  // Handler for adding delivery
  const handleAddDelivery = async () => {
    if (upperLimit.trim() !== '' && lowerLimit.trim() !== '' && price.trim() !== '') {
      const newDelivery: Delivery = { distance_from: Number(lowerLimit),
        distance_to: Number(upperLimit), price: Number(price) };
      const updatedDeliveryData = [...deliveryData, newDelivery];

      try {
        await fetchAPIResponse('/settings/delivery/intervals', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify(updatedDeliveryData),
        });

        setDeliveryData(updatedDeliveryData);
        setUpperLimit('');
        setLowerLimit('');
        setPrice('');
      } catch (error) {
        console.error('Error updating delivery intervals:', error);
      }
    }
  };

  // Handler for deleting delivery
  const handleDeleteDelivery = async (index: number) => {
    const updatedDelivery = deliveryData.filter((_, i) => i !== index);

    try {
      await fetchAPIResponse('/settings/delivery/intervals', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(updatedDelivery),
      });

      setDeliveryData(updatedDelivery);
    } catch (error) {
      console.error('Error deleting delivery interval:', error);
    }
  };

  const updateCashbackRate = async () => {
    try {
      await fetchAPIResponse('/settings/cashback', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          rate: cashbackRate
        })
      });

      toast.success("Successfully modified cashback rate.")
    } catch (error) {
      console.error('Error deleting delivery interval:', error);
    }
  };

  const updateLoyaltyStance = async (to: boolean) => {
    try {
      await fetchAPIResponse('/settings/loyalty', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          loyalty_balance_enabled: to
        })
      });

      setLoyalty(to)
      toast.success("Successfully modified loyalty stance.")
    } catch (error) {
      console.error('Error deleting delivery interval:', error);
    }
  };

  const updateGlobalDiscount = async () => {
    try {
      await fetchAPIResponse('/settings/global_discount', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          global_order_discount: globalDiscount
        })
      });

      toast.success("Successfully modified global discount rate.")
    } catch (error) {
      console.error('Error deleting delivery interval:', error);
    }
  };

  const updateShopStatus = async () => {
    try {
      await fetchAPIResponse('/settings/shop/status', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          shop_status: !shopStatus
        })
      });

      toast.success("Successfully modified shop status.")
      setShopStatus(!shopStatus)
    } catch (error) {
      console.error('Error deleting delivery interval:', error);
    }
  };


  return (
      <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
        <Header title={'Settings'} button={false} buttonTitle={''} action={''}/>
        <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 pr-12 mt-6 font-poppins">
          {/* Delivery Interval section */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-mainTextColor">Shop Status</h2>
            <div className="mt-4">
              <input
                  type="checkbox"
                  placeholder="Shop Status"
                  checked={shopStatus}
                  onChange={(e) => updateShopStatus()}
                  className="mt-1 w-6 h-6 appearance-none rounded-full border-2 bg-white checked:bg-primary focus:ring-2 focus:ring-primary focus:ring-offset-2 cursor-pointer"
              />
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-mainTextColor">Cashback Rate</h2>
            <div className="mt-4">
              <input
                  type="text"
                  placeholder="Cashback Rate"
                  value={cashbackRate}
                  onChange={(e) => setCashbackRate(() => Number(e.target.value))}
                  className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
              <button onClick={updateCashbackRate} className="bg-[#1490C2] text-white rounded-md p-2 mt-5">Set Cashback
                Rate
              </button>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-mainTextColor">Loyalty State</h2>
            <div className="mt-4 flex flex-row items-center gap-3">
              <span>Loyalty Balance:</span>
              <input
                  type="checkbox"
                  checked={loyalty}
                  onChange={(e) => {
                    updateLoyaltyStance(e.target.checked)
                  }}
                  className=""
              />
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-mainTextColor">Global Order Discount Rate</h2>
            <div className="mt-4">
              <input
                  type="text"
                  placeholder="Order Discount Rate"
                  value={globalDiscount}
                  onChange={(e) => setGlobalDiscount(() => Number(e.target.value))}
                  className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
              <button onClick={updateGlobalDiscount} className="bg-[#1490C2] text-white rounded-md p-2 mt-5">Set Global
                Order Discount
              </button>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-mainTextColor">Delivery Interval</h2>
            <div className="mt-4">
              <input
                  type="text"
                  placeholder="Lower Limit (kms)"
                  value={lowerLimit}
                  onChange={(e) => setLowerLimit(e.target.value)}
                  className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
              <input
                  type="text"
                  placeholder="Upper Limit (kms)"
                  value={upperLimit}
                  onChange={(e) => setUpperLimit(e.target.value)}
                  className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
              <input
                  type="text"
                  placeholder="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
              <button onClick={handleAddDelivery} className="bg-[#1490C2] text-white rounded-md p-2 mt-5">Add Delivery
              </button>
            </div>
          </div>
          <div className='w-full overflow-visible flex-grow mt-4'>
            <div className="w-full overflow-visible">
              <div className='w-full grid grid-cols-4 font-bold my-4 text-mainTextColor'>
                <div>Lower Limit (kms)</div>
                <div>Upper Limit (kms)</div>
                <div>Price</div>
                <div>Action</div>
              </div>
              <hr className='border-t border-[#cdcdd0]'/>
              {deliveryData.map((delivery, index) => (
                  <div key={index} className='w-full grid grid-cols-4 my-4'>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{delivery.distance_from}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{delivery.distance_to}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>${delivery.price}</p>
                    </div>
                    <div className='flex items-center space-x-4'>
                      <button onClick={() => handleDeleteDelivery(index)} className="text-[#C41E3A]">Delete</button>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Settings;
